import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";
import { setUserFct } from "../../../core/appStore";
import { signOut } from "../../../core/auth";

const getLoggedUser = async () => {
  try {
    const { data } = await client.get(`user/session`, {
      timeout: 2000, // since it can be heavy too
      headers: {
        Authorization: `Bearer ${getItem("auth")}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    setUserFct(data);
    return data as any;
  } catch (error: any) {
    console.log("error", error.response.status);
    if (error.response && error.response.status === 401) {
      signOut();
    }
  }
};

export function useLoggedUser() {
  return useQuery({
    queryKey: ["get-logged-user"],
    queryFn: getLoggedUser,
  });
}
